import React, { useEffect } from "react";

const GvbWiki = () => {
    useEffect(() => {
        if ("window" in global) {
            window.open("https://staffpanda.notion.site/31895e970aea45ad9f1c23b967503692?v=79f1d50b8de840debd87b3b2acfc7cac&pvs=4");
        }
    }, []);

    return (
        <div />
    );
};

export default GvbWiki;
